@font-face {
  font-family: "Arvo";
  font-display: auto;
  src: local("Arvo"),
    url(https://fonts.gstatic.com/s/arvo/v9/rC7kKhY-eUDY-ucISTIf5PesZW2xOQ-xsNqO47m55DA.woff2)
      format("woff2");
}

.main {
  background-color: #e4e5e6 !important;
}

.required {
  color: red;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.sel-ske-ase > .ant-select-selection {
  height: 64px;
}

.ant-modal-root .notifModal {
  z-index: 2000;
}

.ant-notification {
  z-index: 1060;
}

#chat-bot-launcher-container, #chat-bot-frame-container {
  margin-bottom: 80px;
}
