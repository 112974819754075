.sidebar {
  background: #ffffff !important;
}

.sidebar .nav-link {
  color: #696a67;
  background: white;
}

.sidebar .nav-link:hover {
  color: black;
  background: #ffcb0f;
}

.sidebar .nav-icon:hover {
  color: black;
}

.sidebar .nav-link.active {
  color: black;
  background-color: #e4e5e6 !important;
  border-radius: 30px 0px 0px 30px;
  background: white;
}

.sidebar .nav-link.active .nav-icon {
  color: white;
}

.nav-item a:hover {
  color: black;
  border-radius: 30px 0px 0px 30px;
}

.nav-item li {
  background-color: white !important;
}

.sidebar .nav-link .nav-icon {
  color: black;
}

.sidebar .nav-link.active .nav-icon {
  color: black;
}

.sidebar .nav-dropdown.open .nav-link {
  color: #696a67;
  border-left: 0;
}

.sidebar .nav-dropdown.open {
  color: #014b1c;
  background-color: transparent;
}

.sidebar .sidebar-minimizer {
  background-color: transparent;
}

.mt-auto {
  background-color: white !important;
}

.sidebar .sidebar-minimizer:hover {
  background-color: #ffcb0f !important;
  border-radius: 30px 0px 0px 30px;
}

.BreadCrumb {
  background-color: red !important;
}
