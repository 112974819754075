.ant-btn {
  color: #f86c6b !important;
  background-color: transparent !important;
  border-color: #f86c6b !important;
}

.ant-btn:hover {
  color: white !important;
  background-color: #f86c6b !important;
  border-color: #f86c6b !important;
}

.ant-btn-primary {
  color: #00ae41 !important;
  background-color: transparent !important;
  border-color: #59cc50 !important;
}

.ant-btn-primary:hover {
  color: white !important;
  background-color: #00ae41 !important;
  border-color: #00ae41 !important;
}

.btn-back {
  color: #00ae41;
  background-color: transparent;
  border-color: #59cc50;
}

.Btn-Submit {
  margin-left: 10px;
}

.btn-login {
  width: 100%;
  height: 31.5px;
  border-radius: 6px;
  border: solid 0.5px transparent;
  background-color: #295caa;
  color: white;
}

.btn-signUp {
  width: 100%;
  height: 31.5px;
  border-radius: 6px;
  border: solid 0.5px #295caa;
  background-color: white;
  color: #43425d;
}

.btn-back:hover {
  color: #00ae41;
  background-color: #c8ced3;
  border-color: #59cc50;
}

.btn-success {
  color: #00ae41;
  background-color: transparent;
  border-color: #59cc50;
}

.btn-danger {
  color: #f86c6b;
  background-color: transparent;
  border-color: #f86c6b;
}


.btn-warning {
  color: #ffc107;
  background-color: transparent;
  border-color: #ffc107;
}

.btn-primary-sm {
  color: #20a8d8;
  background-color: transparent;
  border-color: #20a8d8;
}

.btn-primary-sm:hover {
  color: white;
  background-color: #20a8d8;
  border-color: #20a8d8;
}
.btn-submit-primary {
  color: white;
  background-color: #20a8d8;
  border-color: #20a8d8;
}

.tn-submit-primary:hover {
  color: white;
  background-color: #20a8d8;
  border-color: #20a8d8;
}

.btn-login-sm {
  color: White;
  background-color: #ffcb0f;
  border-color: #ffcb0f;
}

.btn-login-sm:hover {
  color: white;
  background-color: #d3a80c;
  border-color: #d3a80c;
}

.btn-formOnline {
  color: #43425d;
  background-color: transparent;
  border-color: #d9d9d9;
}

.btn-formOnline:hover {
  color: white;
  background-color: #20a8d8;
  border-color: #20a8d8;
}


.nav-pills-custom .nav-link {
  color: #aaa;
  background: #fff;
  position: relative;
}

.nav-pills-custom .nav-link.active {
  color: #45b649;
  background: #fff;
}
.mySpan{
  /* writing-mode: vertical-lr; 
  font-size: 20px;
  margin-left: 20px;
  margin-top: 120px;
  margin-bottom: 120px;
  transform: rotate(180deg);
  border: 1px solid;
  padding: 10px;
  box-shadow: 10px 5px #888888; */
  font-size: 18px;
  margin-left: 270px;
  margin-right: 270px;
  }
 
.tabPane{
    box-shadow: -5px 5px 5px -5px #333; 
}

.ant-tabs-tab {
  color:  #363636 !important;
}

.ant-tabs-tab-active {
  color: #ffcb0f !important;
}
.ant-tabs-ink-bar {
  border-bottom: 5px solid #ffcb0f !important;
}
.flex-log-book {
  display: flex !important;
  flex-direction: row !important;
  flex-grow: 4;

}
 .pbounce {
  font-size: 10;
  text-align: center;
  padding: 5px;
  background-color: #111;
  color: #fff;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, .3);

  font-weight: bold
}


.animated {
  animation-duration: 2.5s;
  animation-fill-mode: both;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
  40% {transform: translateY(-30px);}
  60% {transform: translateY(-15px);}
}
.bounce {
  animation-name: bounce;
}
.masterAlatBerat::placeholder {
  width: 149px;
  height: 11.5px;
  opacity: 0.58;
  font-family: Arial;
  font-size: 12.5px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
}
.inputAlatBerat::placeholder {
  width: 180x;
  height: 20.5px;
  opacity: 0.58;
  margin-right: 50px;
  font-family: Arial;
  font-size: 13.5px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
}







    
  

  






